const {
  REACT_APP_LMS_BASE_URL,
  REACT_APP_STUDIO_BASE_URL,
  REACT_APP_PUBLIC_PATH,
  REACT_APP_CSRF_TOKEN_API_PATH,
  REACT_APP_LEARNING_BASE_URL,
  REACT_APP_DASHBOARD_BASE_URL,
  REACT_APP_LOGO_URL,
  REACT_APP_FAVICON_URL,
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
  REACT_APP_SPEECHCRAFT_BASE_URL,
  REACT_APP_PATHWAYS_BASE_URL,
  REACT_APP_PANEL_URL,
  REACT_APP_TOASTMASTERS_WEBSITE_URL
} = process.env;

const LMS_BASE_URL = REACT_APP_LMS_BASE_URL;
const CMS_BASE_URL = REACT_APP_STUDIO_BASE_URL;
const TOASTMASTER_BASE_URL = REACT_APP_DASHBOARD_BASE_URL;
const TOASTMASTER_API_URL = '/api';
const TOASTMASTER_AUTH_URL = `${LMS_BASE_URL}${REACT_APP_CSRF_TOKEN_API_PATH}`;
const PUBLIC_PATH = `${REACT_APP_PUBLIC_PATH || ''}`;
const LEARNING_APP_BASE_URL = REACT_APP_LEARNING_BASE_URL;
const PATHWAYS_BASE_URL = REACT_APP_PATHWAYS_BASE_URL;
const SPEECHCRAFT_BASE_URL = REACT_APP_SPEECHCRAFT_BASE_URL;
const PANEL_BASE_URL = REACT_APP_PANEL_URL || '';
const TOASTMASTERS_WEBSITE_URL = REACT_APP_TOASTMASTERS_WEBSITE_URL || 'https://www.toastmasters.org/'

export {
  LMS_BASE_URL,
  CMS_BASE_URL,
  TOASTMASTER_BASE_URL,
  TOASTMASTER_API_URL,
  TOASTMASTER_AUTH_URL,
  PUBLIC_PATH,
  LEARNING_APP_BASE_URL,
  REACT_APP_LOGO_URL,
  REACT_APP_FAVICON_URL,
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
  SPEECHCRAFT_BASE_URL,
  PATHWAYS_BASE_URL,
  PANEL_BASE_URL,
  TOASTMASTERS_WEBSITE_URL
};

export const EDX_LOGIN_COOKIE = 'edxloggedin';

/*
============================================
  Toastmaster user's roles
============================================
*/
export const ROLE: any = {
  ADMIN: {
    id: 1,
    name: 'Admin',
    isChecked: false,
  },
  SPEECHCRAFT_COORDINATOR: {
    id: 2,
    name: 'Coordinator',
    isChecked: false,
    code: 'EDLYSCC',
  },
  CLUB_MEMBER: {
    id: 3,
    name: 'Club Member',
    isChecked: false,
  },
  DISTRICT_LEADER: {
    id: 4,
    name: 'District Leader',
    isChecked: false,
  },
  CLUB_OFFICER: {
    id: 5,
    name: 'Club Officer',
    isChecked: false,
  },
  SPEECHCRAFTER: {
    id: 6,
    name: 'Speechcrafter',
    isChecked: false,
    code: 'EDLYSCA',
  },
  BASE_CAMP_MANAGER: {
    id: 7,
    name: 'Base Camp Manager',
    isChecked: false,
  },
};

export const COURSE_CATEGORY = {
  SUGGESTED: 'suggested',
  COMPLETED: 'completed',
};

export const DEFAULT_PAGE_SIZE = 20;

export const BADGES_FILTER_TYPE = {
  COMPLETED: 'completed',
  AVAILABLE: 'available',
  ALL: 'all',
};

export const COURSE_STATUS = {
  REGISTERED: 'registered',
  AVAILABLE: 'available',
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed',
};

export const INITIAL_FILTERS_STATE = {
  category: {},
  subCategory: {},
  language: {},
};

export const SORT_OPTIONS = [
  { label: 'All Filters', value: 'All Filters' },
  { label: 'Shared with me', value: 'shared' },
  { label: 'Recently Viewed', value: 'recently viewed' },
  { label: 'Favorites', value: 'favorites' },
];

export const ACCEPTED_VIDEO_FORMATS = [
  'mpeg',
  'wmv',
  'mov',
  'x-msvideo',
  'mp4',
  'quicktime',
  '3gpp',
  'x-flv',
  'x-ms-wmv',
];

export const REQUESTS_SORT_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'To Do', value: 'pending' },
  { label: 'Submitted', value: 'submitted' },
];

export const REQUESTS_SORT_OPTIONS_PATHWAYS = [
  { label: 'All', value: '' },
  { label: 'To Do', value: 'pending' },
  { label: 'Submitted', value: 'submitted' },
];

export const APPROVALS_SORT_OPTIONS_PATHWAYS = [
  { label: 'All', value: '' },
  { label: 'Approved', value: 'approved' },
  { label: 'Denied', value: 'denied' },
  { label: 'To Do', value: 'pending' },
  { label: 'Reversed', value: 'reversed' },
];

export const REQUESTS_SORT_OPTIONS_SPEECHCRAFT = [...REQUESTS_SORT_OPTIONS];

export const REQUEST_TYPES = {
  APPROVAL: 'approval',
  EVALUATION: 'evaluation',
};

export const REQUEST_STATUS = {
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  DENIED: 'Denied',
};

export const APPROVAL_REQUEST_STATUS: any = {
  Denied: 'Denied',
  Pending: 'Review',
  Approved: 'Approved',
  Reversed: 'Reversed',
};

export const NOTIFICATION_TYPES = {
  evaluationRequest: 'evaluation_request',
  evaluationRequestReview: 'evaluation_request_review',
  feedbackSent: 'feedback_sent',
  feedbackRequested: 'feedback_requested',
  completionRequest: 'completion_request',
  courseEnrollment: 'course_enrollment',
  completionReview: 'completion_review',
  badgeEarned: 'badge_earned',
  completionReviewNotifyBCM: 'completion_review_notify_bcm',
  resourceShared: 'resource_shared',
  evaluationDirectReview: 'evaluation_direct_review',
  preCourseEnrollment: 'pre_course_enrollment',
  completionRequestFirstReminder: 'completion_request_first_reminder',
  completionRequestSecondReminder: 'completion_request_second_reminder',
  feedbackBadgeEarned: 'feedback_badge_earned',
  courseInactivity: 'course_inactivity',
  pathwaysMentorProgramEnrollment: 'pathways_mentor_program_enrollment',
  clubCoachModuleEnrollment: 'club_coach_module_enrollment',
  speechcrafterCertificate: 'speechcrafter_certificate',
  speechcrafterLastSelfAssessment: 'speechcrafter_last_self_assessment',
  speechcraftEventEnd: 'end_speechcraft_event',
  clubAssociationChange: 'club_association_change',
  reverse_completion_project: 'reverse_completion_project',
  reverse_completion_level: 'reverse_completion_level',
  reverse_completion_path: 'reverse_completion_path',
};

export const BADGE_HASH_TYPES = {
  learnerBadge: '#learner-badge',
  feedbackBadge: '#feedback-badge',
  speechBadge: '#speech-badge',
};

export const BADGE_TYPES = {
  learnerBadge: 'PW',
  learnerFeedbackBadge: 'PW_FB',
  speechFeedbackBadge: 'SC_FB',
  speechBadge: 'SC',
};

export const NOTIFICATION_INTERVAL = 500000;

export const MFE_CONFIG = {
  speechcraft: {
    config: {
      showSelfAssessments: true,
      showAchievements: true,
      showPathLearning: false,
      showFeedback: true,
      showResources: true,
      showCoordinatorHome: true,
      speechcrafters: true,
      showSpeechcrafterHome: true,
    },
  },
  pathways: {
    config: {
      showSelfAssessments: false,
      showAchievements: true,
      showPathLearning: true,
      showFeedback: true,
      showResources: true,
      showCoordinatorHome: false,
      speechcrafters: false,
      showSpeechcrafterHome: false,
    },
  },
};

export const SPEECH_CRAFT_MFE = 'speechcraft';

export enum Role {
  coordinator = 'EDLYSCC',
  speechcrafter = 'EDLYSCA',
}

export enum AdminUsers {
  isSystemUser = 'is-system-user'
}

export enum Levels {
  level1 = 'level-1',
  level2 = 'level-2',
  level3 = 'level-3',
  level4 = 'level-4',
  level5 = 'level-5',
}

export const ErrorTypes = {
  Platform_Access_Denied: 'NO_PLATFORM_ACCESS',
  No_Current_Event: 'NO_CURRENT_EVENT',
  Event_Expired: 'EVENT_EXPIRED',
  Not_Event_Participant: 'NOT_EVENT_PARTICIPANT',
};

export const ErrorMessages = {
  NO_PLATFORM_ACCESS:
    'This page you are trying to reach has restricted access. If you feel you have received this message in error, log out and back in.',
  NO_CURRENT_EVENT:
    'This page you are trying to reach has restricted access. If you feel you have received this message in error, log out and back in.',
  EVENT_EXPIRED:
    'This page you are trying to reach has restricted access. If you feel you have received this message in error, log out and back in.',
  NOT_EVENT_PARTICIPANT:
    'This page you are trying to reach has restricted access. If you feel you have received this message in error, log out and back in.',
};

export enum TICourses {
  PMP = 'pathways mentor program',
  DTM = 'distinguished toastmaster',
  CCP = 'club coach program',
  CBI = 'club building initiative',
}
