import { styled, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledTypography: any = styled(Typography)`
  color: ${({ theme }) => theme.palette.ternary.tertiary1};
  margin: 0;
`;

export const EvaluationContentHolder = styled('div')`
  overflow: hidden;
  text-align: center;

  .evaluation-details {
    overflow: hidden;
    text-align: left;
    padding-top: 40px;

    .info-text {
      text-transform: capitalize;
    }

    .text {
      color: ${({ theme }) => theme.palette.ternary.dark};
    }
  }

  .form-access-icon {
    display: inline-block;
    vertical-align: top;
    margin: 0 2px;
  }

  .footer-text {
    color: ${({ theme }) => theme.palette.ternary.dark};

    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.secondary.main};

      &:hover {
        opacity: 0.9;
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }
`;

export const HeaderHolder = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  h3 {
    margin: 0;
  }
`;

export const IconHolder = styled('div')`
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const FormControlWrapper = styled('div')`
  label {
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
    color: ${({ theme }) => theme.palette.ternary.greyShade};
  }
`;