import {
  Avatar,
  Box,
  Stack,
  styled,
  Typography,
  alpha,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { theme } from 'styles/theme';

export const FeedbackWrapper = styled(Box)`
  max-width: 900px;
  margin: 40px auto 20px;
`;

export const FeedbackItemBox = styled(Box)`
  padding: 1.25rem;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.palette.ternary.white};
`;

export const FeedbackItemBlock = styled('div')`
  background: ${({ theme }) => theme.palette.ternary.light};
  border-radius: 3px;
  padding: 10px;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 5px;

  &.full-width {
    width: 100%;
    margin: 0;
  }

  & .username-title {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.ternary.greyShade};
  }
`;

export const FeedbackItemStack = styled(Stack)`
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
`;

export const FeedbackItemAvatar = styled(Avatar)`
  width: 46px;
  height: 46px;
`;

export const FeedbackReponseBox = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  margin-left: 1.5rem;
  align-items: stretch;
`;

export const FeedbackBadgeBox = styled(Stack)`
  gap: 0.9rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem;
  max-width: 135px;
  min-width: 135px;
  border-right: 1px solid ${({ theme }) => theme.palette.ternary.dark};
  text-align: center;
`;

export const FeedbackButtonsBox = styled(Stack)`
  & .MuiButton-root {
    min-width: 160px;
  }
`;

export const BadgeAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  padding: 13px;
  background: ${({ theme }) => theme.palette.ternary.white};
  color: ${({ theme }) => theme.palette.ternary.greyShade};

  & img {
    object-fit: contain;
    object-position: center;
  }
`;

export const BadgeText = styled(Typography)`
  line-height: 1.17;
  font-weight: 600;
  font-size: 14px;
`;

export const FeedbackText = styled(Typography)`
  /* color: ${alpha('#000', 0.6)}; */
`;

export const SliderWrapper = styled(Box)`
  & .slick-track {
    display: flex;
    align-items: stretch;
    padding: 12px 2px;

    & .slick-slide {
      height: inherit !important;
      margin-left: 5px;

      & > div {
        height: 100%;
      }

      & .MuiAvatar-root {
        width: 90px;
        height: 90px;
        background: ${({ theme }) => theme.palette.ternary.white};
        padding: 8px;

        & img {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }
    }

    & .slick-list [data-slick-index='0'] {
      margin-left: 0;
    }
  }

  & .slick-arrow {
    color: ${({ theme }) => theme.palette.secondary.main};
    width: 24px;
    height: auto;
    top: 12px;
    bottom: 12px;
    margin: auto;
    transform: none;
    z-index: 2;

    &:before {
      display: none;
    }

    &:focus-visible {
      outline: 3px solid ${({ theme }) => theme.palette.ternary.focusBlue};
      outline-offset: 2px;
    }

    &.slick-disabled {
      svg {
          color: ${({ theme }) => theme.palette.ternary.greyShade};
      }
    }
  }
`;

export const BadgeConatiner = styled(Box)`
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.palette.ternary.light};
  border-radius: 4px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  min-height: 100%;

  &.selected {
    outline: 2px solid ${({ theme }) => theme.palette.ternary.tertiary1};
    outline-offset: -2px;
    background: ${alpha(theme.palette.ternary.tertiary1, 0.05)};
  }

  &.focused {
    outline: 1px solid ${({ theme }) => theme.palette.secondary.dark};
    outline-offset: -1px;
  }

  &.focused,
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 41, 0.16);
  }

  &:active {
    background: ${alpha(theme.palette.ternary.tertiary1, 0.2)};
  }
`;

export const BadgeCheckbox = styled(Checkbox)`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const BadgeFormLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'badgeImage',
})((props: any) => {
  return `
  margin: 0;
  gap: 1rem;
  padding: 125px 0.625rem 0.625rem;
  width: 100%;
  height: 100%;

  & .MuiFormControlLabel-label {
    text-align: center;
    line-height: 1.17;
    font-weight: 600;
    font-size: 14px;
    word-break: break-word;
  }

  &::before {
    position: absolute;
    top: 12px;
    content: '';
    width: 100px;
    height: 100px;
    background: url(${props.badgeImage});
    background-color: ${theme.palette.ternary.white};
    background-position: center;
    background-size: 70% 70% !important;
    background-repeat: no-repeat;
    border-radius: 50%;
    z-index: 10;
  }
`;
});

export const NoFeedback = styled('div')`
  max-width: 380px;
  margin: 100px auto;
  text-align: center;

  img {
    max-width: 160px;
    margin: 0 auto 15px;
  }
  p {
    font-size: 18px;
    line-height: 32px;
  }
`;

export const ImageWrapper = styled('div')`
  margin: 20px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
  }
`;

export const FeedbackTextSection = styled(Stack)`
  flex: 1;
  justify-content: space-between;
  padding: 20px 0 0;
`;

export const FeedbackActionsHolder = styled(Stack)`
  span {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border-radius: 4px;
      background: ${({ theme }) => theme.palette.ternary.main};
    }
  }
`;
