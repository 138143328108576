import axios from 'axios';
import Cookies from 'universal-cookie';
import { camelizeKeys, decamelizeKeys } from 'humps';
// @ts-ignore
import { getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';
import { initializeSession } from 'routes';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';
import { getPlatformName } from 'utils/utility';
import { toast } from 'react-toastify';

export enum APIError {
  network = 'network',
  server = 'server',
  notFound = 'notFound',
  canceled = 'canceled',
}

export const processApiError = (error: any): any => {
  const cookies = new Cookies();
  if (error.message === APIError.canceled) {
    return APIError.canceled;
  }

  

  if (!error.response) {
    return APIError.network;
  }

  if (
    error.response?.status &&
    [500, 501, 502, 503, 504].includes(error.response?.status)
  ) {
    return APIError.server;
  }

  if (error.response?.status === 401) {
    cookies.remove('csrftoken');
    window.location.replace(getConfig().LOGIN_URL);
    return {
      status: 401,
      message: 'An unknown error occurred.',
    };
  }

  if (error.response?.status === 400 && (error?.response?.data?.code !== 'inactive_user' || !error?.response?.data?.code?.includes('inactive_user'))) {
    // TODO Update errors structure form BE like fields errors, generic errors etc
    const errorMessage = error.response?.data?.non_field_errors?.[0];
    if (errorMessage) return errorMessage;

    return 'Something went wrong.';
  }

  if (!error.response?.data) {
    return 'An unknown error occurred.';
  }

  return error.response?.data;
};

const axiosClient = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.response.use(
  response => {
    const newResponse = { ...response };
    const isJsonResponse =
      response.headers['content-type']?.includes('application/json');

    if (response.data && isJsonResponse) {
      newResponse.data = camelizeKeys(response.data);
    }

    return newResponse;
  },
  async error => {
    const cookies = new Cookies();
    if (error?.response?.status === 302 && error?.response?.data?.code === 'inactive_user') {
      window.location.replace(error?.response?.data?.url);
    }

    if (error.response?.status === 403) {
      if(error?.response?.data?.detail.includes('Authentication credentials were not provided.')) {
        cookies.remove('csrftoken');
        window.location.replace(getConfig().LOGOUT_URL);
      }
    }
    
    const shouldRetry = error.config.hasOwnProperty('retries')
      ? error.config.retries < 4
      : true;
    
    
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.message.includes('CSRF Failed') &&
      shouldRetry
    ) {
      await initializeSession();
      if (error.config.retries) {
        error.config.retries += 1;
      } else {
        error.config.retries = 1;
      }

      return axiosClient.request(error.config);
    }

    if (error.response?.status === 404 && !window.location.pathname.includes('feedback/evaluation-form')) {
      toast.error('This action is no longer available. Please contact educationprograms@toastmasters.org if you have any questions', {
        onClose: () => {
          window.location.reload();
        },
        autoClose: 3000
      });
      return Promise.reject({});
    }

    if(error?.response?.data?.code === 'inactive_user' || error?.response?.data?.code?.includes('inactive_user')) {
      toast.error('This action is no longer available. Please contact educationprograms@toastmasters.org if you have any questions', {
        onClose: () => {
          window.location.replace(getConfig().BASE_URL);
        },
        autoClose: 3000
      });
      return Promise.reject({});
    }

    return Promise.reject(processApiError(error));
  },
);

axiosClient.interceptors.request.use((config: any) => {
  const cookies = new Cookies();
  let csrftoken = cookies.get('csrftoken');
  config.headers = {
    ...config.headers,
    'USE-JWT-COOKIE': true,
    'X-Platform': process.env.REACT_APP_PLATFORM_NAME || 'pathways',
  };

  if (csrftoken) {
    config.headers = { ...config.headers, 'X-CSRFToken': csrftoken };
  }

  if (config.csrftoken) {
    config.headers = { ...config.headers, 'X-CSRFToken': config.csrftoken };
  }

  const newConfig = { ...config };

  if (
    config?.headers &&
    config.headers['Content-Type'] === 'multipart/form-data'
  ) {
    return config;
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  if (config.signal) {
    newConfig.signal = config.signal;
  }

  return newConfig;
});

export const clientGet = (url: string, options = {}) =>
  axiosClient.get(url, options);

export const clientPost = async (url: string, data: any, options = {}) =>
  await getAuthenticatedHttpClient().axiosClient.post(url, data, options);

export const clientPut = async (url: string, data: any, options = {}) =>
  await getAuthenticatedHttpClient().axiosClient.put(url, data, options);

export const clientPatch = async (url: string, data: any, options = {}) =>
  await getAuthenticatedHttpClient().axiosClient.patch(url, data, options);

export const clientDelete = async (url: string, options = {}) =>
  await getAuthenticatedHttpClient().axiosClient.delete(url, options);

export default axiosClient;
