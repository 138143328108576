import { alpha } from '@mui/material';
import { DeviceDown } from './breakpoints';

const globalStyle = (theme: any) => ({
  ':root': {
    '--toastify-color-success': `${theme.palette.success.main}`,
    '--toastify-color-warning': `${theme.palette.warning.main}`,
    '--toastify-color-error': `${theme.palette.error.main}`,
  },
  '*': {
    'box-sizing': 'border-box',
    'scrollbar-width': 'thin',
    'scrollbar-color': `${theme.palette.ternary.greyShade} ${alpha(theme.palette.ternary.main, 0.35)}`,
  },
  '*:after, *:before': {
    'box-sizing': 'inherit',
  },
  '*:focus-visible': {
    outline: `3px solid ${theme.palette.ternary.focusBlue}`,
    'outline-offset': '-3px',
  },
  html: {
    'scroll-behavior': 'smooth',
    height: '100%',
  },
  body: {
    margin: 0,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    'font-family': theme.typography.fontFamily,
    height: '100%',
    color: theme.palette.text.primary,
  },
  '.color-primary': {
    color: theme.palette.primary.main,
  },
  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb': {
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.ternary.main,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.ternary.greyShade,
    border: '0px solid transparent',
    borderRadius: '3px',
  },
  code: {
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
  '#root': {
    display: 'flex',
    'flex-direction': 'column',
    height: '100%',
  },
  a: {
    textDecoration: 'none',
    '&:focus-visible': {
      'outline-offset': '2px',
    },
  },
  '.MuiButtonBase-root': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.MuiInputBase-input': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  button: {
    outline: 'none',
    fontFamily: `${theme.typography.fontFamily} !important`,
    '&:focus-visible': {
      'outline-offset': '2px',
    },
    "&.btn-italic": {
      "font-style": "italic",
    },
  },
  'h1, h2, h3, h4, h5, h6, .MuiTypography-body1, .MuiTypography-body2': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.mw150': {
    minWidth: '150px !important',
  },
  '.mw120': {
    minWidth: '120px !important',
  },
  '.text-italic': {
    fontStyle: 'italic',
  },
  '.text-end': {
    'text-align': 'right',
  },
  '.mr-5': {
    'margin-right': '5px',
  },
  '.text-capitalize': {
    'text-transform': 'capitalize',
  },
  '.text-uppercase': {
    'text-transform': 'uppercase',
  },
  '.asteric': {
    'color': '#ac1b1b',
  },
  '.MuiCalendarPicker-root button': {
    '&:focus-visible': {
      'outline-width': '2px',
      'outline-offset': '0',
    },
  },
  '.Mui-focusVisible .MuiTouchRipple-root': {
    display: 'none',
  },
  ".textarea-italic textarea::placeholder": {
    fontStyle: 'italic'
  },
  '.MuiPickersCalendarHeader-labelContainer .MuiButtonBase-root:focus-visible':
    {
      'outline-offset': '-4px',
    },
  '.slick-next:before': {
    color: theme.palette.primary.main,
  },
  '.slick-prev:before': {
    color: theme.palette.primary.main,
  },
  '.slick-list': {
    margin: '0 -5px',
  },
  '.fw-700': {
    fontWeight: '700 !important',
  },
  '.fw-600': {
    fontWeight: '600 !important',
  },
  '.fw-500': {
    fontWeight: '500 !important',
  },
  '.fw-400': {
    fontWeight: '400 !important',
  },
  '.reset-btn': {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    padding: 0
  },
  '.d-none': {
    display: 'none !important',
  },
  '.d-md-flex': {
    [`@media ${DeviceDown.md}`]: {
      display: 'flex !important',
    },
  },
  '.w-100': {
    width: '100%',
  },
  '.d-md-none': {
    [`@media ${DeviceDown.md}`]: {
      display: 'none !important',
    },
  },
  '.secondary-options .MuiPaper-root .MuiMenuItem-root': {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  '.selected-checked-icon .MuiPaper-root .MuiMenuItem-root': {
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.ternary.tertiary1, 0.08),
      color: theme.palette.ternary.greyShade,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'flex-start',

      '&::after': {
        content: '"check_icon"',
        fontFamily: 'Material Icons',
        width: '20px',
        height: '18px',
        color: theme.palette.ternary.tertiary1,
        fontSize: '20px',
        marginLeft: 'auto',
      },
    },
  },
  '@page': {
    size: 'auto',
    margin: '30px 0',
  },
  '.page-break':{
    'page-break-inside': 'avoid'
  },
  '#menu-sortBy li': {
    'text-transform': 'capitalize',
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.ternary.tertiary1, 0.08),
      color: 'inherit'
    }
  },
  '.gap-10': {
    'gap': '10px'
  },
  '.alignSelfEnd': {
    'align-self': 'flex-end',
  },
  '.sr-only': {
    'width': '1px',
    'height': '1px',
    'padding': '0',
    'margin': '-1px',
    'overflow': 'hidden',
    'clip': 'rect(0, 0, 0, 0)',
    'borderWidth': '0',
    'white-space': 'nowrap',
    'position': 'absolute' as const,
  },
  '#scrollableDiv .MuiContainer-root': {
    'padding': '0 15px'
  }
});

export default globalStyle;
